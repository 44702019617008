<template>
  <!-- 我的银行卡 -->
  <div class="page-my-bank">
    <div v-if="bankLists && bankLists.length" class="hasbank">
      <div
        v-for="(item, index) in bankLists"
        :key="index"
        class="bank-item"
        :style="{
          background: item.bankBackColor
        }"
      >
        <div class="item-cont">
          <div class="cont-top">
            <div class="iconLogo">
              <p><img :src="item.logo" alt="" /></p>
              <span>{{ item.openBankName }}</span>
            </div>
            <span>**** {{ item.bankAccount4 }}</span>
          </div>
          <div class="df dk-style alc">
            <div class="df jc-end " @click="resultBank(item)">
              <div class="again-btn dk-btn-box btn-style-again">重新绑卡</div>
            </div>
            <div class="df alc" >
              <div class="dk-btn-box m-r-20">设置为代扣卡</div>
              <img
                v-if="item.isDefault == 1"
                src="./img/checked.png"
                class="checked-style"
              />
              <img
                v-else
                @click="checkedBank(item)"
                src="./img/checkbox.png"
                class="checked-style"
              />
            </div>
          </div>
          <!-- <div class="df jc-end " @click="resultBank(item)">
           <div class="again-btn dk-btn-box">重新绑卡</div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-else class="nocard">
      <img src="./img/bank.png" alt="" />
      <p class="title">暂无绑定银行卡</p>
    </div>
    <div class="add-bank">
        <div @click="Jump('/addAccount')" class="add-btn-bank">添加银行卡</div>
      </div>
    <van-dialog
      v-model:show="checked"
      title="温馨提示"
      show-cancel-button
      @cancel="checked = false"
      @confirm="confirm"
    >
      <div>
        <p class="content">
          将尾号 {{ lastDigits }} 的银行卡，设置为代扣卡，请确认是否设置？
        </p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref } from "vue";
import { Toast } from "vant";
import { Jump } from "@/utils/routh";
import APP from "@/utils/App";
import API from "../../server/api";
import { routerTag } from "../../utils/index";

export default {
  setup() {
    const state = reactive({ bankLists: [] });
    // 查看银行卡列表
    const bankList = async () => {
      const bankListData = await API.getMycardList();
      if (bankListData.code == 200) {
        state.bankLists = bankListData.data;
      }
    };
    const addAccount = () => {
      APP.JUMP_TO(`${routerTag}addAccount`);
    };
    onMounted(() => {
      state.userId = localStorage.getItem("userId");
      state.creditNo = localStorage.getItem("creditNo");
      bankList();
      // APP.SET_TITLE('我的银行卡')
    });

    const lastDigits = ref("");
    const checked = ref(false);
    const checkedBank = value => {
      const { bankAccount, bankAccount4 } = value;
      checked.value = true;
      state.checkedBankNo = bankAccount;
      lastDigits.value = bankAccount4;
    };
    const resultBank = value => {
      let params = {
        bank: value.bankAccount,
        name: value.openBankName,
        code: value.openBankCode,
      };
      localStorage.setItem("WithholdBank", JSON.stringify(params));
      Jump('/addAccount');
    };
    const confirm = async () => {
      try {
        let params = {
          userId: state.userId,
          bankAccount: state.checkedBankNo,
          loanNo: localStorage.getItem("loanNos")
        };
        const { code } = await API.setDefault(params);
        if (code == 200) {
          checked.value = false;
          Toast("设置成功");
          bankList();

        }
      } catch (error) {
        console.log(error);
      }
    };
    return {
      lastDigits,
      addAccount,
      ...toRefs(state),
      checked,
      confirm,
      checkedBank,
      resultBank,
      Jump
    };
  }
};
</script>
<style lang="less" src="./index.less" scoped></style>
<style lang="less" scoped>
.add-bank {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.add-btn-bank {
  padding: 20px 42px;
  // background: #fd1b7d;
  font-size: 32px;
  border-radius: 50px;
  border: 2px solid #fd1b7d;
  color: #fd1b7d;
}
.dk-style {
  margin-top: 40px;
  height: 60px;
  justify-content: space-between;
}
.jc-end {
  justify-content: flex-end;
}
.dk-btn-box {
  font-size: 28px;
  color: #fff;
  font-weight: bold;

}
.m-r-20 {
  margin-right: 20px;
}

.content {
  padding: 40px;
}

.again-btn {
  height: 60px;
  line-height: 60px;
}
.checked-style {
  width: 40px;
  height: 40px;
}
.btn-style-again {
  color: #fff;
  border: 2px solid #fff;
  padding: 0 24px;
  border-radius: 50px;
  letter-spacing: 1px;
}
</style>
